import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Search or Share Video Creation References.
        </p>
        <input alt={"Video URL"} placeholder={"Video Sharable Link"}></input>
        <button>Search</button>
      </header>
    </div>
  );
}

export default App;
